import React, { memo } from 'react'
// @ts-expect-error modules is not yet converted to ts
import Text from '@typeform/ginger/dist/components/typography/text/v2'
import AnimatedContainer from 'components/atoms/animated-container'
import { fontSize } from '@typeform/ginger/dist/constants/typography'

import TrackingLocation, {
  TRACKING_LOCATIONS,
} from '../common/tracking-location'
import HeadlineAndTextModule from '../common/headline-and-text-module'
import { slideAndFade } from '../common/animations'

import {
  DecorativeContainer,
  Stats,
  StatsContainer,
} from './why-typeform-section.styles'

const statsList = [
  {
    percentage: 96,
    description: 'of customers say they have a better brand experience',
  },
  {
    percentage: 95,
    description: 'of customers say they gather more data, more easily',
  },
  {
    percentage: 87,
    description: 'of customers say they reveal deeper insights from data',
  },
]

const ProfilesSection = () => {
  return (
    <TrackingLocation name={TRACKING_LOCATIONS.STATS}>
      <HeadlineAndTextModule
        title='Why Typeform?'
        description='Because after switching to us...'
      />
      <DecorativeContainer data-testid='why-typeform-section'>
        <AnimatedContainer
          entryAnimations={slideAndFade}
          entryConfig={{ threshold: 0.2 }}
        >
          <StatsContainer>
            {statsList.map(({ percentage, description }, index) => (
              <Stats key={description} position={index}>
                <Text
                  as='span'
                  largeViewportsFontSize={fontSize.x5Large}
                  mediumViewportsFontSize={fontSize.x4Large}
                  smallViewportsFontSize={fontSize.x4Large}
                >
                  {percentage}%
                </Text>
                <Text
                  as='span'
                  mediumViewportsFontSize={fontSize.medium}
                  smallViewportsFontSize={fontSize.small}
                >
                  {description}
                </Text>
              </Stats>
            ))}
          </StatsContainer>
        </AnimatedContainer>
      </DecorativeContainer>
    </TrackingLocation>
  )
}

ProfilesSection.displayName = 'ProfilesSection'

export default memo(ProfilesSection)
